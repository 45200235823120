import { FunctionComponent} from "react";

import { Seo } from "../utilities/Seo";
const privacy: FunctionComponent = () => {
  return (
    <>
      <Seo />
      <div className="justify-center items-start flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-buttons-button-lg">
        <div
          className="container mt-[50px] mb-[auto] my-6 mx-auto p-[20px] w-[90%]"
          style={{
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            maxWidth: "800px",
            margin: "auto",
          }}
        >
          <div className="grid grid-cols-1">
            <div className="w1-[132px] mq360:w-[auto] flex flex-col items-start justify-start pt-px px-0 pb-0 box-border">
              <img
                className="h-10 relative max-w-full overflow-visible shrink-0 object-cover mq360:ms-[8px] mq550:max-w-[116px]"
                alt=""
                src="/logo@2x.png"
              />
            </div>
          </div>
          <div className="grid grid-cols-1 mt-[50px] mb-[50px] text-justify">
          <h1 className="text-center">Privacy Policy</h1>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      <b>Effective Date: </b>
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      Welcome to <b>ResAI Technologies Private Limited</b> (&quot;ResAI,&quot;
      &quot;we,&quot; &quot;us,&quot; or &quot;our&quot;). We are committed to
      protecting the privacy and security of our users’ personal data. This
      <b>Privacy Policy</b> outlines how we collect, use, disclose, protect, and
      share your personal data when you access or use our website, applications,
      and related services (collectively referred to as &quot;Services&quot;).
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      This Privacy Policy is governed by Indian laws, particularly the
      <b>Information Technology Act, 2000</b>, the
      <b
        >Information Technology (Reasonable Security Practices and Procedures
        and Sensitive Personal Data or Information) Rules, 2011</b
      >
      (referred to as <b>IT Rules 2011</b>), and aligns with global best
      practices such as the <b>General Data Protection Regulation</b> (GDPR) of
      the European Union (EU) and other international privacy standards.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      By using the ResAI platform, you consent to the terms described in this
      policy. If you do not agree with the terms and conditions of this Privacy
      Policy, please do not use our Services.
    </p>
    
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      <img
        src="/Privacy%20Policy_html_1120881d76d9da8a.gif"
        width="100%"
        height="2"
      />
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      <b>1. Introduction to Key Concepts</b>
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      To help you better understand your rights and our obligations, we’ve
      included definitions for important terms related to privacy laws and
      practices:
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      <b>1.1. Personal Data</b>
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      &quot;Personal Data&quot; refers to any information relating to an
      identified or identifiable individual, such as your name, email address,
      contact details, educational qualifications, and employment history. This
      may also include details such as job preferences, skills, language
      preferences, or location data.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      <b>1.2. Sensitive Personal Data</b>
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      Sensitive Personal Data, as defined under Indian law, includes information
      such as passwords, financial data (e.g., payment methods or bank account
      information), physical or mental health details, sexual orientation,
      biometric information, and any other data classified as sensitive under
      the <b>IT Rules 2011</b>.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      <b>1.3. Processing of Personal Data</b>
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      Processing refers to any activity involving personal data, including but
      not limited to its collection, storage, use, modification, sharing, and
      deletion. We are the &quot;Data Controller,&quot; meaning that we
      determine how and why your personal data is processed, while third-party
      service providers may act as &quot;Data Processors&quot; on our behalf.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
    <img
        src="/Privacy%20Policy_html_1120881d76d9da8a.gif"
        width="100%"
        height="2"
      />
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      <b>2. Information We Collect</b>
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      We collect various categories of data to provide our Services, enhance the
      user experience, and comply with legal obligations. The data collection
      methods include direct input from users, automated data collection through
      technologies like cookies, and data obtained from third-party platforms
      with your consent.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      <b>2.1. Personal Data You Provide Directly</b>
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      When you use our platform to build resumes, portfolios, or access
      career-related tools, you may provide the following types of personal
      data:
    </p>
    <ul>
      <li>
        <p className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Account Information</b>: Name, email address, password, phone
          number, and other registration details.
        </p>
      </li>

      <li>
        <p className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Career Information</b>: Details like job experience, skills,
          education, certifications, and languages you speak.
        </p>
      </li>

      <li>
        <p className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Documents</b>: Uploaded files, such as resumes, cover letters, job
          applications, certifications, and portfolios.
        </p>
      </li>

      <li>
        <p className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Contact Information</b>: When interacting with customer support,
          you may provide phone numbers, email addresses, and details of the
          issues you're experiencing.
        </p>
      </li>
    </ul>

    <p className="line-height: 108%; margin-bottom: 0.28cm">
      <b>2.2. Data Collected Automatically</b>
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      When you access or use the Services, we collect data about your device and
      usage behavior to optimize the platform’s performance and user experience.
      This includes:
    </p>
    <ul>
      <li>
        <p className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Device Information</b>: IP address, browser type, operating system,
          device type, and other technical details.
        </p>
      </li>

      <li>
        <p className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Usage Data</b>: Pages you visit, time spent on the platform, and
          actions such as clicks, file uploads, or downloads.
        </p>
      </li>

      <li>
        <p className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Cookies and Tracking Technologies</b>: We use cookies, web beacons,
          and other technologies to gather information on how you interact with
          the platform, save your preferences, and personalize content. (Please
          see Section 10 for more on cookies).
        </p>
      </li>
    </ul>

    <p className="line-height: 108%; margin-bottom: 0.28cm">
      <b>2.3. Information from Third Parties</b>
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      We may obtain data from external sources, including:
    </p>
    <ul>
      <li>
        <p className="line-height: 108%; margin-bottom: 0.28cm">
          <b>LinkedIn or Other Job Platforms</b>: When you connect your profile
          to ResAI, we may collect data like job titles, employment history, and
          skills listed on these platforms.
        </p>
      </li>

      <li>
        <p className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Publicly Available Sources</b>: Data from public job boards,
          professional directories, or other open resources may be collected to
          match your skills with job opportunities.
        </p>
      </li>

      <li>
        <p className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Third-Party Service Providers</b>: We may also receive information
          from partners who help facilitate payment processing, analytics, or
          job recommendations.
        </p>
      </li>
    </ul>

    <p className="line-height: 108%; margin-bottom: 0.28cm">
    <img
        src="/Privacy%20Policy_html_1120881d76d9da8a.gif"
        width="100%"
        height="2"
      />
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      <b>3. Purpose of Processing Personal Data</b>
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      The personal data we collect is processed for a variety of reasons related
      to delivering our Services, improving user experience, and complying with
      legal requirements. Here are the primary purposes:
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      <b>3.1. Providing and Enhancing Our Services</b>
    </p>
    <ul>
      <li>
        <p className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Resume Building and Skill Extraction</b>: We use your personal data
          to generate resumes, cover letters, and portfolios, as well as to
          analyze your skill set using AI-driven algorithms. The platform
          identifies key competencies, matches them with job requirements, and
          suggests improvements to optimize your professional presentation.
        </p>
      </li>

      <li>
        <p className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Job Recommendations</b>: Based on your resume and career
          preferences, we recommend job openings from our extensive database,
          ensuring the roles are aligned with your qualifications and interests.
        </p>
      </li>

      <li>
        <p className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Skill Gap Analysis and Course Recommendations</b>: Our platform
          reviews your resume and skill set, identifying any gaps that may limit
          your career progression. It then recommends online courses,
          certifications, or learning paths to help bridge those gaps.
        </p>
      </li>
    </ul>

    <p className="line-height: 108%; margin-bottom: 0.28cm">
      <b>3.2. Communication and Customer Support</b>
    </p>
    <ul>
      <li>
        <p className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Service-Related Communications</b>: We may contact you via email,
          phone, or messages to inform you about your account, notify you of
          service updates, or respond to support inquiries.
        </p>
      </li>

      <li>
        <p className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Marketing Communications</b>: With your consent, we may send you
          promotional offers, newsletters, or updates on new features and
          services. You can opt out of these communications at any time.
        </p>
      </li>
    </ul>

    <p className="line-height: 108%; margin-bottom: 0.28cm">
      <b>3.3. Processing Payments</b>
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      When you purchase premium services, such as tailored resume reviews or
      subscription-based access, we process payment-related data, including
      credit card information, billing address, and transaction history. All
      payments are securely handled through third-party payment processors,
      adhering to industry standards for financial security.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      <b>3.4. Platform Improvement and Analytics</b>
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      We process data to analyze user behavior, identify trends, and improve
      platform performance. This includes running A/B tests, identifying bugs,
      and enhancing usability. Aggregated, anonymized data is often used for
      analytics to better understand how users interact with the platform.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      <b>3.5. Compliance with Legal and Regulatory Obligations</b>
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      ResAI complies with applicable laws and regulations, such as the
      <b>Information Technology Act, 2000</b> and other data protection laws
      that require us to:
    </p>
    <ul>
      <li>
        <p className="line-height: 108%; margin-bottom: 0.28cm">
          Store records of consent for data collection and processing.
        </p>
      </li>

      <li>
        <p className="line-height: 108%; margin-bottom: 0.28cm">
          Provide data to authorities if required by law or under a legal
          request.
        </p>
      </li>

      <li>
        <p className="line-height: 108%; margin-bottom: 0.28cm">
          Ensure secure and proper retention of data in line with legal
          requirements.
        </p>
      </li>
    </ul>

    <p className="line-height: 108%; margin-bottom: 0.28cm">
      <b>3.6. Fraud Prevention and Security</b>
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      To protect our platform from misuse, fraud, or unauthorized access, we
      implement fraud detection measures, monitor security incidents, and
      authenticate users as necessary. Your data helps us maintain the integrity
      and safety of our Services.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
    <img
        src="/Privacy%20Policy_html_1120881d76d9da8a.gif"
        width="100%"
        height="2"
      />
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      <b>4. Legal Basis for Processing Personal Data</b>
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      Depending on your location and the applicable laws, we rely on several
      legal bases to process your data. These include:
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm"><b>4.1. Consent</b></p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      Where necessary, we process personal data based on your explicit consent.
      For example, when you provide your data for receiving job alerts or when
      you agree to the use of cookies on our platform. You have the right to
      withdraw your consent at any time (see Section 9 for more on your rights).
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      <b>4.2. Performance of a Contract</b>
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      We process data to fulfill the contract between you and ResAI, such as
      when you use our Services to create a resume or when you subscribe to our
      premium features.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      <b>4.3. Compliance with Legal Obligations</b>
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      We may process your data to comply with legal and regulatory requirements.
      For example, we are required to retain certain types of data for tax and
      audit purposes or respond to legal requests from authorities.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      <b>4.4. Legitimate Interests</b>
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      In some cases, we process personal data based on legitimate interests. For
      instance, we process your data to improve our Services, prevent fraud, and
      safeguard our platform. We ensure that our legitimate interests do not
      override your rights and freedoms.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
    <img
        src="/Privacy%20Policy_html_1120881d76d9da8a.gif"
        width="100%"
        height="2"
      />
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      <b>5. Sharing of Personal Data</b>
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      ResAI does not sell your personal data to third parties. However, we may
      share your data with certain entities to provide our Services, comply with
      legal obligations, or protect our legitimate interests. Below is a
      detailed description of the data-sharing scenarios:
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      <b>5.1. Service Providers</b>
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      We engage trusted third-party vendors to help with platform operations.
      These service providers include:
    </p>
    <ul>
      <li>
        <p className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Cloud Storage Providers</b>: For secure storage of data and
          ensuring access to your documents across devices.
        </p>
      </li>

      <li>
        <p className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Payment Processors</b>: To handle transactions securely and in
          compliance with payment industry standards (e.g., PCI-DSS).
        </p>
      </li>

      <li>
        <p className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Analytics Providers</b>: To track platform usage, user
          interactions, and trends for platform improvements.
        </p>
      </li>
    </ul>

    <p className="line-height: 108%; margin-bottom: 0.28cm">
      All third-party providers are required to adhere to strict data protection
      standards and can only process data based on our instructions.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      <b>5.2. Business Transfers</b>
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      In the event of a merger, acquisition, restructuring, or sale of assets,
      personal data may be transferred to the new owner or merged entity. We
      will notify users of such business changes and provide you with an option
      to manage your data rights if ownership changes.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      <b>5.3. Legal Compliance</b>
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      We may share your personal data with regulatory authorities or law
      enforcement agencies if required by law or in response to a legitimate
      request. This includes:
    </p>
    <ul>
      <li>
        <p className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Court Orders and Legal Requests</b>: We may disclose your data if
          required to comply with a court order, legal proceeding, or government
          regulation.
        </p>
      </li>

      <li>
        <p className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Fraud Prevention</b>: Sharing data with authorities to protect
          against fraudulent activity or illegal conduct on our platform.
        </p>
      </li>
    </ul>

    <p className="line-height: 108%; margin-bottom: 0.28cm">
      <b>5.4. Third-Party Integrations</b>
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      If you connect your ResAI account with third-party platforms (e.g.,
      LinkedIn or job boards), we will share data as required to facilitate the
      integration. The third-party services are responsible for their own
      privacy practices, and we encourage you to review their policies.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
    <img
        src="/Privacy%20Policy_html_1120881d76d9da8a.gif"
        width="100%"
        height="2"
      />
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      <b>6. International Data Transfers</b>
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      As ResAI operates in multiple jurisdictions, your personal data may be
      transferred to and processed in countries outside of India, including
      countries that may not have the same level of data protection laws as
      India or your home country.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      <b>6.1. Data Transfers to Third Countries</b>
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      When transferring data to countries outside India, we take appropriate
      safeguards in line with applicable privacy laws such as the
      <b>IT Rules, 2011</b> and international frameworks like GDPR. This
      includes:
    </p>
    <ul>
      <li>
        <p className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Standard Contractual Clauses</b>: Implementing contractual clauses
          approved by data protection authorities to ensure the protection of
          personal data.
        </p>
      </li>

      <li>
        <p className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Data Protection Agreements</b>: Requiring third-party processors to
          adhere to robust data protection standards regardless of where they
          are located.
        </p>
      </li>
    </ul>

    <p className="line-height: 108%; margin-bottom: 0.28cm">
      If you are based in the European Union or other countries with strict data
      protection laws, we ensure that appropriate measures are in place to
      safeguard your data during such transfers.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
    <img
        src="/Privacy%20Policy_html_1120881d76d9da8a.gif"
        width="100%"
        height="2"
      />
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      <b>7. Data Retention</b>
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      ResAI retains personal data for as long as necessary to fulfill the
      purposes outlined in this Privacy Policy or as required by law. This
      retention period includes:
    </p>
    <ul>
      <li>
        <p className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Account-Related Data</b>: As long as your account remains active,
          we retain the necessary data to provide you with services such as
          resume creation, job alerts, and job recommendations.
        </p>
      </li>

      <li>
        <p className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Legal Compliance</b>: Data may be retained for longer periods when
          necessary to comply with legal obligations, such as tax records or
          fraud prevention measures.
        </p>
      </li>

      <li>
        <p className="line-height: 108%; margin-bottom: 0.28cm">
          <b>User-Requested Deletion</b>: If you request the deletion of your
          account, we will erase your personal data in accordance with our data
          retention policies, except where retention is required by law or for
          legitimate business purposes.
        </p>
      </li>
    </ul>

    <p className="line-height: 108%; margin-bottom: 0.28cm">
      Once your data is no longer required for these purposes, we will securely
      delete, anonymize, or de-identify it in compliance with applicable laws.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      <b>8. Data Security</b>
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      We take your privacy and data security very seriously. ResAI implements
      appropriate physical, electronic, and administrative safeguards to protect
      your personal data from unauthorized access, disclosure, alteration, and
      destruction. Our security measures align with both Indian laws, such as
      the Information Technology Act, 2000, and global best practices like the
      General Data Protection Regulation (GDPR).
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      8.1. <b>Technical and Organizational Measures</b><br />
      We have implemented a variety of security protocols, including encryption,
      firewalls, and secure server environments. Data transferred between your
      device and our platform is protected using SSL/TLS encryption.
      Additionally, we utilize two-factor authentication (2FA) and regularly
      monitor our systems for any potential vulnerabilities.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      8.2. <b>Data Encryption</b><br />
      All personal data you provide is encrypted both in transit and at rest,
      using industry-standard encryption technologies. This ensures that even if
      unauthorized parties gain access to the data, they cannot read or misuse
      it.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      8.3. <b>Access Controls</b><br />
      We restrict access to your personal data to authorized personnel who
      require such information to perform their duties. Access is granted based
      on the principle of least privilege, ensuring that only the necessary data
      is accessible for specific job functions.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      8.4. <b>Regular Security Audits</b><br />
      We conduct routine security audits and vulnerability assessments to
      identify and rectify potential security flaws. These audits include both
      internal reviews and third-party security assessments. Any findings are
      promptly addressed to maintain the highest levels of protection.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      8.5. <b>Incident Response</b><br />
      In the unlikely event of a data breach or security incident, ResAI has a
      comprehensive incident response plan in place. We will notify affected
      users as required by law and take immediate action to mitigate any risks.
      In compliance with GDPR, if the breach poses a significant risk to your
      rights and freedoms, we will notify you within 72 hours of discovery.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
    <img
        src="/Privacy%20Policy_html_1120881d76d9da8a.gif"
        width="100%"
        height="2"
      />
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      <b>9. User Rights</b>
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      ResAI is committed to ensuring that you have full control over your
      personal data and can exercise your privacy rights. Depending on your
      location and the applicable laws, such as the GDPR, you have the following
      rights:
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      9.1. <b>Right to Access</b><br />
      You have the right to request access to the personal data we hold about
      you. Upon request, we will provide you with a copy of your data, subject
      to applicable legal limitations.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      9.2. <b>Right to Rectification</b><br />
      If your personal data is inaccurate or incomplete, you have the right to
      request corrections or updates. We encourage you to ensure that your
      account details remain accurate and up to date.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      9.3. <b>Right to Erasure (&quot;Right to be Forgotten&quot;)</b><br />
      In certain circumstances, you may request the deletion of your personal
      data. This right applies, for example, if the data is no longer necessary
      for the purpose it was collected, or if you withdraw your consent. We will
      comply with your request, except where retention is required for legal or
      legitimate business purposes.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      9.4. <b>Right to Restrict Processing</b><br />
      You can request that we limit the processing of your personal data in
      specific situations, such as when the accuracy of the data is disputed or
      if you object to its processing.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      9.5. <b>Right to Data Portability</b><br />
      You have the right to request that your personal data be transferred to
      another service provider in a structured, commonly used, and
      machine-readable format.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      9.6. <b>Right to Object</b><br />
      You can object to the processing of your personal data for marketing
      purposes or when processing is based on legitimate interests. We will
      respect your request unless we have compelling legal grounds to continue
      processing.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      9.7. <b>Right to Withdraw Consent</b><br />
      If we process your data based on your consent, you can withdraw your
      consent at any time. This will not affect the legality of processing done
      before consent was withdrawn.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      9.8. <b>Exercising Your Rights</b><br />
      To exercise any of these rights, please contact us via the contact details
      provided in Section 14. We aim to respond to all requests within 30 days,
      although this period may be extended if the request is complex.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
    <img
        src="/Privacy%20Policy_html_1120881d76d9da8a.gif"
        width="100%"
        height="2"
      />
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      <b>10. Cookies and Tracking Technologies</b>
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      ResAI uses cookies and other tracking technologies to enhance your
      experience on our platform, provide personalized content, and analyze
      usage patterns. This section outlines our use of these technologies and
      how you can manage your preferences.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      10.1. <b>What Are Cookies?</b><br />
      Cookies are small text files stored on your device by websites you visit.
      They help websites recognize your device and store information about your
      preferences or past actions. We use different types of cookies to improve
      our platform's performance and functionality.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      10.2. <b>Types of Cookies We Use</b>
    </p>
    <ul>
      <li>
        <p className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Essential Cookies:</b> These are necessary for the basic operation
          of the website. They allow you to navigate the platform, use essential
          features, and access secure areas.
        </p>
      </li>

      <li>
        <p className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Performance Cookies:</b> These cookies collect anonymous data on
          how users interact with our platform. We use this information to
          improve the site's functionality and user experience.
        </p>
      </li>

      <li>
        <p className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Functionality Cookies:</b> These cookies remember your preferences
          and choices, such as language settings or login details, to provide a
          more personalized experience.
        </p>
      </li>

      <li>
        <p className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Advertising Cookies:</b> We may use cookies to display targeted
          advertisements based on your interests and browsing behavior.
        </p>
      </li>
    </ul>

    <p className="line-height: 108%; margin-bottom: 0.28cm">
      10.3. <b>Managing Cookies</b><br />
      You can control and manage cookies through your browser settings. You may
      also use cookie management tools to adjust your preferences. Note that
      disabling certain cookies may affect your ability to use certain features
      of our platform.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      10.4. <b>Other Tracking Technologies</b><br />
      In addition to cookies, we use other tracking technologies like web
      beacons and pixel tags. These technologies help us understand how users
      interact with our emails and online content, allowing us to tailor our
      communications and marketing strategies.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
    <img
        src="/Privacy%20Policy_html_1120881d76d9da8a.gif"
        width="100%"
        height="2"
      />
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      <b>11. Third-Party Links</b>
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      Our platform may contain links to external websites or services that are
      not operated by ResAI. This Privacy Policy does not apply to third-party
      websites, and we encourage you to review their privacy policies before
      interacting with those sites.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      11.1. <b>Third-Party Integrations</b><br />
      We may integrate third-party services, such as LinkedIn or other job
      platforms, to enhance the functionality of our platform. These third-party
      services may collect and process your data independently, and ResAI is not
      responsible for their privacy practices.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      11.2. <b>No Responsibility for External Websites</b><br />
      While we take steps to partner with reputable services, we cannot
      guarantee the privacy or security of your data once it is shared with
      external sites. Any data you provide to third-party websites is subject to
      their privacy policies.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
    <img
        src="/Privacy%20Policy_html_1120881d76d9da8a.gif"
        width="100%"
        height="2"
      />
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      <b>12. Children's Privacy</b>
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      ResAI’s services are not directed toward individuals under the age of 18.
      We do not knowingly collect personal data from children under 18 without
      verified parental consent. If we discover that we have inadvertently
      collected personal data from a child under 18, we will delete that data
      immediately.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      12.1. <b>Parental Consent</b><br />
      In cases where a child under 18 uses our services with parental consent,
      we will process their personal data according to the provisions of this
      Privacy Policy and applicable child protection laws.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      12.2. <b>Reporting Violations</b><br />
      If you become aware that a child under 18 has provided us with personal
      data without parental consent, please contact us using the details
      provided in Section 14, and we will take steps to remove the information.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
    <img
        src="/Privacy%20Policy_html_1120881d76d9da8a.gif"
        width="100%"
        height="2"
      />
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      <b>13. Changes to the Privacy Policy</b>
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      We may update this Privacy Policy periodically to reflect changes in our
      practices, technologies, or legal requirements. Any modifications will
      become effective upon posting the updated policy on our website. We
      encourage you to review this Privacy Policy regularly to stay informed
      about how we are protecting your personal data.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      13.1. <b>Notification of Changes</b><br />
      If we make any material changes to this policy, we will notify you via
      email or through a prominent notice on our platform. This will give you an
      opportunity to review the new terms and provide feedback if necessary.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      13.2. <b>User Consent for Updates</b><br />
      Your continued use of our services following the effective date of the
      updated Privacy Policy will signify your acceptance of the revised terms.
      If you do not agree with the changes, you may discontinue using our
      services.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
    <img
        src="/Privacy%20Policy_html_1120881d76d9da8a.gif"
        width="100%"
        height="2"
      />
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      <b>14. Contact Details</b>
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      If you have any questions, concerns, or requests regarding your personal
      data or this Privacy Policy, please contact us at:
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      <b>ResAI Technologies Private Limited</b><br />
      [ Address]<br />
      Email: [Contact Email]<br />
      Phone: [Contact Number]
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      We are committed to responding to your inquiries and requests promptly and
      in accordance with applicable laws.
    </p>
    <p className="line-height: 108%; margin-bottom: 0.28cm">
      <br />
      <br />
      <br />
    </p>
            
            
            
            
            
           
          </div>
        </div>
      </div>
     
    </>
  );
};

export default privacy;
